import { ICoreContext, ITimeFormatOptions } from '@msdyn365-commerce/core';

/**
 * Formats time.
 * @param context - Core context.
 * @param time - Time to display.
 * @returns String value.
 */
// Example usage: formatTime(this.props.context, new Date(CreatedTime)

export const formatTime = (context: ICoreContext, time: Date | undefined) => {
    if (time === undefined) {
        return '';
    }

    const timeOptions: ITimeFormatOptions = { hour: 'numeric', minute: 'numeric', second: 'numeric', timeZone: 'UTC' };
    return context.cultureFormatter.formatDate(time, timeOptions);
};

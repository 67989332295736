import React from 'react';
import { ICoreContext } from '@msdyn365-commerce/core';

import { IOrderDetailsResources } from '../order-details.props.autogenerated';
import { IOrderDetailResponse } from '../../../actions/get-order-details.action';
import {
    formatDate,
    formatCurrency,
    formatNumber,
    mapOverallStatus,
    formatOverallStatusCodeForTooltip,
    convertSapToHtmlLinebreaks,
    convertHtmlToPlainText
} from '../../../utilities';
import { NO_DATA_FALLBACK, TOTAL_PRICE_DEFAULT } from '../../../configuration';
import Tooltip from '../../../components/tooltip';

export default function generalInformationSection({
    context,
    orderDetails,
    resources
}: {
    context: ICoreContext;
    orderDetails: IOrderDetailResponse;
    resources: IOrderDetailsResources;
}) {
    if (!orderDetails) {
        return <p>{resources.orderDetailsLoadingLabel}</p>;
    }

    const isEmptyPrice = (price: string) => {
        const regex = new RegExp(`\\b${TOTAL_PRICE_DEFAULT}\\b`);

        return regex.test(price);
    };

    return (
        <>
            <div className='row'>
                <div className='col-md-3 col-sm-6'>
                    <strong className='ms-order-details__text-auto-hyphens'>{resources.orderDetailsGeneralTableOrderNumber}</strong>
                    <p>{orderDetails.OrderDetail?.OrderNumber}</p>
                </div>
                <div className='col-md-3 col-sm-6'>
                    <strong className='ms-order-details__text-auto-hyphens'>{resources.orderDetailsGeneralTableOrderType}</strong>
                    <p>{orderDetails.OrderDetail?.OrderType}</p>
                </div>
                <div className='col-md-3 col-sm-6'>
                    <strong className='ms-order-details__text-auto-hyphens'>
                        <Tooltip
                            label={resources.orderDetailsGeneralTableOverallStatus}
                            content={formatOverallStatusCodeForTooltip(resources)}
                        />
                    </strong>
                    <p>{mapOverallStatus(resources).getText(orderDetails.OrderDetail?.Status)}</p>
                </div>
                <div className='col-md-3 col-sm-6'>
                    <strong className='ms-order-details__text-auto-hyphens'>
                        {resources.orderDetailsGeneralTableOrderInformationRecipient}
                    </strong>
                    <p className='ms-order-details__text-overflow-ellipses' title={orderDetails.OrderDetail?.Recipient}>
                        {orderDetails.OrderDetail?.Recipient}
                    </p>
                </div>
                <div className='col-md-3 col-sm-6'>
                    <strong className='ms-order-details__text-auto-hyphens'>{resources.orderDetailsGeneralTableCustomerPo}</strong>
                    <p>{orderDetails.OrderDetail?.PoNumber}</p>
                </div>
                <div className='col-md-3 col-sm-6'>
                    <strong className='ms-order-details__text-auto-hyphens'>{resources.orderDetailsGeneralCustomerReferenceDate}</strong>
                    <p>
                        {orderDetails.OrderDetail?.ConfirmedDate && formatDate(context, new Date(orderDetails.OrderDetail.ConfirmedDate))}
                    </p>
                </div>
                <div className='col-md-3 col-sm-6'>
                    <strong className='ms-order-details__text-auto-hyphens'>{resources.orderDetailsGeneralTableTotalWeight}</strong>
                    <p>{`${formatNumber(context, Number(orderDetails.OrderDetail.TotalWeight))} ${orderDetails.OrderDetail.Unit}`}</p>
                </div>
                <div className='col-md-3 col-sm-6'>
                    <strong className='ms-order-details__text-auto-hyphens'>
                        <Tooltip
                            label={resources.orderDetailsGeneralTableTotalPrice}
                            content={resources.orderDetailsGeneralTableTotalPriceToolTip}
                        />
                    </strong>
                    <p className='ms-order-details__block-item'>
                        {isEmptyPrice(orderDetails.OrderDetail?.TotalPrice) || !Number(orderDetails.OrderDetail?.TotalPrice)
                            ? NO_DATA_FALLBACK
                            : formatCurrency(context, orderDetails.OrderDetail?.Currency, Number(orderDetails.OrderDetail?.TotalPrice))}
                    </p>
                </div>
                <div className='col-md-3 col-sm-6'>
                    <strong className='ms-order-details__text-auto-hyphens'>{resources.orderDetailsGeneralIncoterm1}</strong>
                    <p>{orderDetails.OrderDetail?.Incoterm1}</p>
                </div>
                <div className='col-md-3 col-sm-6'>
                    <strong className='ms-order-details__text-auto-hyphens'>{resources.orderDetailsGeneralIncoterm2}</strong>
                    <p>{orderDetails.OrderDetail?.Incoterm2}</p>
                </div>
                <div className='col-md-3 col-sm-6'>
                    <strong className='ms-order-details__text-auto-hyphens'>{resources.orderDetailsGeneralTablePaymentTerms}</strong>
                    <p>{orderDetails.OrderDetail?.PaymentTerms}</p>
                </div>
            </div>
            <h4 className='ms-order-details__section-heading'>{resources.orderDetailsAdditionalInformationSectionHeading}</h4>
            <div className='row'>
                <div className='col-md-12'>
                    {orderDetails.OrderDetail?.HeaderTexts && Object.keys(orderDetails.OrderDetail?.HeaderTexts).length ? (
                        <p
                            className='lxs-create-order__text-auto-hyphens'
                            dangerouslySetInnerHTML={{
                                __html: convertSapToHtmlLinebreaks(
                                    convertHtmlToPlainText(orderDetails.OrderDetail?.HeaderTexts[0].TextValue)
                                )
                            }}
                        />
                    ) : (
                        <p>{NO_DATA_FALLBACK}</p>
                    )}
                </div>
            </div>
        </>
    );
}

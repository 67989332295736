/**
 * Map Item Status Code To Text.
 * @param code - String mapping code.
 * @param resources - Object with translation keys.
 * @returns String value.
 */

export const mapItemStatusCodeToText = (code: string, resources: any): string => {
    switch (code) {
        case '00':
            return resources.globalStatusItemUnknown;
        case '05':
            return resources.globalStatusItemQueued;
        case '10':
            return resources.globalStatusItemSubmitted;
        case '20':
            return resources.globalStatusItemItemCanceled;
        case '30':
            return resources.globalStatusItemConfirmed;
        case '40':
            return resources.globalStatusItemProcessing;
        case '70':
            return resources.globalStatusItemGoodsDispatched;
        case '80':
            return resources.globalStatusItemInvoiced;
        case '90':
            return resources.globalStatusItemCompleted;
        default:
            return resources.globalStatusError;
    }
};
export const mapItemStatusCodeDescriptionToText = (code: string, resources: any): string => {
    switch (code) {
        case '00':
            return resources.globalStatusItemUnknownDescription;
        case '05':
            return resources.globalStatusItemQueuedDescription;
        case '10':
            return resources.globalStatusItemSubmittedDescription;
        case '20':
            return resources.globalStatusItemItemCanceledDescription;
        case '30':
            return resources.globalStatusItemConfirmedDescription;
        case '40':
            return resources.globalStatusItemProcessingDescription;
        case '70':
            return resources.globalStatusItemGoodsDispatchedDescription;
        case '80':
            return resources.globalStatusItemInvoicedDescription;
        case '90':
            return resources.globalStatusItemCompletedDescription;
        default:
            return resources.globalStatusErrorDescription;
    }
};

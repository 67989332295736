import React from 'react';
import { ICoreContext } from '@msdyn365-commerce/core';
import { toast } from 'sonner';
import { IOrderDetailDocument } from '../../../actions/get-order-details.action';
import GetDocumentDownload, { GetDocumentDownloadInput } from '../../../actions/get-document-download.action';
import { IOrderDetailsResources } from '../order-details.props.autogenerated';

export default function documentDownloadItem({
    context,
    resources,
    documentItem,
    index
}: {
    context: ICoreContext;
    documentItem: IOrderDetailDocument;
    resources: IOrderDetailsResources;
    index: number;
}) {
    const documentDownloadRefs = React.useRef<HTMLAnchorElement[]>([]);
    const documentDownloadAlreadyDownloaded: number[] = [];

    const getDocumentDownload = async (documentId: string, index: number) => {
        try {
            const link = documentDownloadRefs.current[index] as HTMLAnchorElement;

            // Download and initiate click()/download only once
            if (!documentDownloadAlreadyDownloaded.includes(index)) {
                const actionInput = new GetDocumentDownloadInput(documentId);
                const documentDownload = await GetDocumentDownload(actionInput, context.actionContext);
                const documentContent = `data:application/pdf;base64,${documentDownload.Content}`;
                // Only prepare link if content is available
                if (documentDownload.Content.length === 0) {
                    toast.error(
                        <div className='toast' role='alert'>
                            <div className='toast-header'>
                                <h4>{resources.orderDetailsDownloadErrorHeading}</h4>
                                <button className='toast-close' onClick={() => toast.dismiss()}></button>
                            </div>
                            <div className='toast-body'>
                                <span>{resources.orderDetailsDownloadErrorNoContentDescription}</span>
                            </div>
                        </div>
                    );
                } else {
                    link.href = documentContent;
                    link.download = documentDownload.FileName;
                    documentDownloadAlreadyDownloaded.push(index);
                    link.click();
                }
            }
        } catch (error) {
            toast.error(
                <div className='toast' role='alert'>
                    <div className='toast-header'>
                        <h4>{resources.globalErrorHeading}</h4>
                        <button className='toast-close' onClick={() => toast.dismiss()}></button>
                    </div>
                    <div className='toast-body'>
                        <span>{resources.globalErrorServiceNotAvailable}</span>
                    </div>
                </div>
            );
            console.error('Error document download', error);
            return error;
        }

        return null;
    };

    return (
        <a
            ref={ref => {
                if (!documentDownloadRefs.current[Number(index)] && ref) {
                    documentDownloadRefs.current[Number(index)] = ref;
                }
            }}
            className='ms-order-details__download-link'
            onClick={() => getDocumentDownload(documentItem.DocumentId, index)}
        >
            {documentItem.Name}
        </a>
    );
}

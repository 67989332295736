import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { TOOLTIP_OFFSET, TOOLTIP_X_OFFSET } from '../configuration';

interface ITooltipContainerProps {
    label: string;
    content: string | JSX.Element;
}

const tooltipContainer = ({ label, content }: ITooltipContainerProps): JSX.Element => {
    const ref = useRef(null);
    const [tooltip, setTooltip] = useState<{
        show: boolean;
        position: { top: number; left: number };
        windowSize: { height: number; width: number };
    }>({
        show: false,
        position: { top: 0, left: 0 },
        windowSize: { height: 0, width: 0 }
    });

    const handleMouseEnter = (e: MouseEvent): void => {
        const rect = (e.target as HTMLSpanElement)?.getBoundingClientRect();

        // windowSize is needed before mouseEnter is active. Caused by overflow-x: auto (example: scrolling table)
        setTooltip({
            show: true,
            position: {
                top: rect.top + window.scrollY,
                left: rect.right + window.scrollX + TOOLTIP_OFFSET
            },
            windowSize: {
                height: window.innerHeight,
                width: window.innerWidth
            }
        });
    };

    useEffect(() => {
        if (tooltip.show && ref.current) {
            const rect = ((ref.current as unknown) as HTMLDivElement).getBoundingClientRect();
            const newPosition = { ...tooltip.position };
            if (rect.right > tooltip.windowSize.width) {
                const overflowWidth = rect.right - tooltip.windowSize.width;
                newPosition.left = tooltip.position.left - overflowWidth - TOOLTIP_X_OFFSET;
            }
            if (rect.bottom > window.innerHeight) {
                const overflowHeight = rect.bottom - window.innerHeight;
                newPosition.top = tooltip.position.top - overflowHeight - TOOLTIP_OFFSET;
            }

            setTooltip({
                ...tooltip,
                position: newPosition
            });
        }
    }, [tooltip.show]);

    const handleMouseLeave = (): void => {
        setTooltip({ ...tooltip, show: false });
    };

    return (
        <>
            <span className='lxs-tooltip' onMouseEnter={e => handleMouseEnter(e)} onMouseLeave={handleMouseLeave}>
                {label}
            </span>
            {tooltip.show &&
                createPortal(
                    <div
                        ref={ref}
                        className='lxs-tooltip__content'
                        style={{
                            top: tooltip.position.top,
                            left: tooltip.position.left
                        }}
                    >
                        {content}
                    </div>,
                    document.body
                )}
        </>
    );
};

export default tooltipContainer;

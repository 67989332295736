export const formatAddress = (address: any): string[] => {
    const keys = new Array(9).fill(0).map((_, i) => `ShortAddress${i + 1}`);

    return Object.keys(address)
        .filter(key => keys.includes(key))
        .reduce((acc, key) => {
            if (!!address[String(key)]) {
                acc.push(address[String(key)] as string);
            }
            return acc;
        }, [] as string[]);
};

import React from 'react';
import { STATUS_CODES, OVERALL_STATUS_CODES } from '../configuration';
import { mapItemStatusCodeDescriptionToText, mapItemStatusCodeToText } from './map-item-status-code-to-text';
import { mapOverallStatusCodeDescriptionToText, mapOverallStatus } from './map-overall-status-code-to-text';

export const formatStatusCodeForTooltip = (resources: any) => {
    return (
        <div className='lxs-tooltip__content-status-code'>
            {STATUS_CODES.map(code => (
                <div key={code}>
                    <strong>{mapItemStatusCodeToText(code, resources)}</strong>
                    <p>{mapItemStatusCodeDescriptionToText(code, resources)}</p>
                </div>
            ))}
        </div>
    );
};
export const formatOverallStatusCodeForTooltip = (resources: any) => {
    return (
        <div className='lxs-tooltip__content-status-code'>
            {OVERALL_STATUS_CODES.map(code => (
                <div key={code}>
                    <strong>{mapOverallStatus(resources).getText(code)}</strong>
                    <p>{mapOverallStatusCodeDescriptionToText(code, resources)}</p>
                </div>
            ))}
        </div>
    );
};

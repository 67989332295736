/**
 * Map Overall Status Function.
 * @param resources - Object with translation keys.
 * @returns getter functions for text and code.
 */

export const mapOverallStatus = (resources: any) => {
    const map = new Map<string, string>([
        ['00', resources.globalStatusOverallUnknown],
        ['05', resources.globalStatusOverallQueued],
        ['10', resources.globalStatusOverallSubmitted],
        ['20', resources.globalStatusOverallCanceled],
        ['30', resources.globalStatusOverallConfirmed],
        ['40', resources.globalStatusOverallProcessing],
        ['90', resources.globalStatusOverallCompleted]
    ]);

    /**
     * Get true if status code exists.
     * @param code - Status code.
     * @returns True or false.
     */
    const getCodeExists = (code: string): boolean => {
        return map.has(code);
    };

    /**
     * Get text from status code.
     * @param code - Status code.
     * @returns Translation as string.
     */
    const getText = (code: string): string => {
        return map.get(code) ?? resources.globalStatusError;
    };

    /**
     * Get status code from text.
     * @param text - Translation.
     * @returns Status code as string.
     */
    const getCode = (text: string): string => {
        for (const [key, value] of map.entries()) {
            if (value === text) {
                return key;
            }
        }
        return '';
    };

    return {
        getCodeExists,
        getText,
        getCode
    };
};
export const mapOverallStatusCodeDescriptionToText = (code: string, resources: any): string => {
    switch (code) {
        case '00':
            return resources.globalStatusOverallUnknownDescription;
        case '05':
            return resources.globalStatusOverallQueuedDescription;
        case '10':
            return resources.globalStatusOverallSubmittedDescription;
        case '20':
            return resources.globalStatusOverallCanceledDescription;
        case '30':
            return resources.globalStatusOverallConfirmedDescription;
        case '40':
            return resources.globalStatusOverallProcessingDescription;
        case '90':
            return resources.globalStatusOverallCompletedDescription;
        default:
            return resources.globalStatusErrorDescription;
    }
};

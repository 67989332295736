import React, { FC, ReactNode, createContext, useContext, useEffect, useState } from 'react';
import classname from 'classnames';
import { Module, Node, Modal, ModalBody, ModalFooter, ModalHeader, IModuleProps } from '@msdyn365-commerce-modules/utilities';

interface ModalProviderNewProps {
    children: ReactNode;
    props: any;
}

type ModalContextType = {
    isOpen: boolean;
    setOpen: (data: ModalProviderContentProps) => void;
    setClose: () => void;
};

interface ModalProviderContentProps {
    content: ReactNode;
    header?: string;
    size?: 'sm' | 'lg' | 'xl';
}

export const ModalContext = createContext<ModalContextType>({
    isOpen: false,
    setOpen: () => {},
    setClose: () => {}
});

export const ModalProvider: FC<ModalProviderNewProps> = ({ children, props }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [content, setContent] = useState<ModalProviderContentProps>({ content: null, header: '', size: 'sm' });
    const [isMounted, setIsMounted] = useState<boolean>(false);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    const setOpen = (data: ModalProviderContentProps) => {
        if (data.content) {
            setContent(data);
            setIsOpen(true);
        }
    };

    const setClose = () => {
        setIsOpen(false);
        setTimeout(() => {
            setContent({ content: null, header: '', size: 'sm' });
        }, 300);
    };

    if (!isMounted) {
        return null;
    }

    const modalContainer: IModuleProps = {
        tag: Modal,
        moduleProps: props,
        className: classname({ [`msc-modal__dialog-${content.size}`]: true }),
        isOpen,
        'aria-label': 'open-modal',
        toggle: setClose,
        modalTransition: { timeout: 0 },
        shouldUpdateFocusToFirstElement: true
    };

    const modalHeaderContainer = {
        tag: ModalHeader,
        moduleProps: props,
        className: '',
        toggle: setClose,
        closeButtonTabIndex: 1
    };

    const modalFooterContainer = { tag: ModalFooter, className: '' };

    const modalBodyContainer = { tag: ModalBody, className: '' };

    return (
        <ModalContext.Provider value={{ setOpen, setClose, isOpen }}>
            {children}
            <Module {...modalContainer}>
                <Node {...modalHeaderContainer}>{content.header ?? ''}</Node>
                <Node {...modalBodyContainer}>{content.content}</Node>
                <Node {...modalFooterContainer}>
                    <button onClick={() => setClose()} className='msc-modal__close-button'>
                        <span className='sr-only'>Close</span>
                    </button>
                </Node>
            </Module>
        </ModalContext.Provider>
    );
};

export const useModal = () => {
    const context = useContext(ModalContext);
    if (!context) {
        throw new Error('useModal must be used within the modal provider');
    }
    return context;
};

import { CultureInfoFormatter, ICoreContext } from '@msdyn365-commerce/core';

/**
 * Get the formatted currency.
 * @param props - The props.
 * @param price - The price.
 * @param shouldUseFreePriceText - The shouldUseFreePriceText.
 * @returns The formatted currency.
 */
export const formatCurrency = (context: ICoreContext, currency: string | undefined, price: number | undefined): string => {
    if (price === undefined || currency === undefined) {
        return '';
    }

    const cultureInfoFormatter = new CultureInfoFormatter(context.request.locale, currency);

    return cultureInfoFormatter.formatCurrency(price);
};

import React, { CSSProperties } from 'react';

export type AlertType = 'warning' | 'info' | 'danger' | 'success';

interface IAlertProps {
    type: AlertType;
    message: string;
    label?: string;
    iconSize?: number;
    closable?: boolean;
    onClose?: () => void;
}

const alert = ({ type, message, iconSize = 1, closable = false, onClose }: IAlertProps): JSX.Element => {
    const customStyles = { '--icon-size': `${iconSize}rem` };
    return (
        <div className={`alert alert-${type} msc-alert`} role='alert'>
            <span className='msc-alert__message' style={customStyles as CSSProperties}>
                {message}
            </span>
            {closable && <button aria-label='close' className={`alert-close alert-${type}`} onClick={onClose} />}
        </div>
    );
};

export default alert;

// Filter
export const ORDER_HISTORY_FILTER_STATUS = 'status';
export const ORDER_HISTORY_FILTER_FROM_DATE = 'fromDate';
export const ORDER_HISTORY_FILTER_TO_DATE = 'toDate';
export const ORDER_HISTORY_FILTER_CREATED_ON = 'createdOn';
export const ORDER_HISTORY_FILTER_FULLNAME = 'fullName';

// Table columns
export const ORDER_HISTORY_COLUMN_ORDER_NUMBER = 'orderNumber';
export const ORDER_HISTORY_COLUMN_ACTION = 'action';
export const ORDER_HISTORY_COLUMN_PO_NUMBER = 'poNumber';
export const ORDER_HISTORY_COLUMN_SOLD_TO_NAME = 'soldToName';
export const ORDER_HISTORY_COLUMN_SHIP_TO_NAME = 'shipToName';

export enum OrderStatus {
    Unknown = '00',
    Queued = '05',
    Submitted = '10',
    Canceled = '20',
    Confirmed = '30',
    Processing = '40',
    Completed = '90'
}

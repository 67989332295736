/**
 * The TextId for the HeaderTexts within the create order request, that describes the additional information.
 */
export const CREATE_ORDER_ADDITIONAL_INFORMATION_TEXT_ID = 'Z400';

/**
 * The maximum character limit for the Additional Information text field within the create order request.
 */
export const CREATE_ORDER_ADDITIONAL_INFORMATION_TEXT_LIMIT = 1000;

/**
 * The Value for the EndCustomer Dropdown when the response is 0000000000
 */
export const CREATE_ORDER_NO_ENDCUSTOMER_VALUE = '0000000000';

/**
 * The Value to wait for the cart to be saved. This prevents failing the requests due to many requests in a short time.
 */
export const CREATE_ORDER_SAVE_CART_DELAY = 200;

/**
 * Values that are used for ID attributes in table instances.
 */
export const CREATE_ORDER_COLUMN_ORDER_NUMBER = 'orderNumber';
export const CREATE_ORDER_COLUMN_ACTION = 'action';
export const CREATE_ORDER_COLUMN_PRODUCT_NAME = 'productName';
export const CREATE_ORDER_COLUMN_PRODUCT_NUMBER = 'productNumber';
export const CREATE_ORDER_COLUMN_UNIT = 'unit';
export const CREATE_ORDER_COLUMN_INCOTERM = 'incoterm';
export const CREATE_ORDER_COLUMN_SALES_ORG = 'salesOrg';
export const CREATE_ORDER_COLUMN_BUSINESS_UNIT = 'businessUnit';
export const CREATE_ORDER_COLUMN_DIVISION_TEXT = 'divisionText';
export const CREATE_ORDER_COLUMN_SALES_ORG_TEXT = 'salesOrgText';
export const CREATE_ORDER_COLUMN_DISTRIBUTION_CHANNEL = 'distributionChannel';
export const CREATE_ORDER_COLUMN_END_CUSTOMER = 'endCustomer';
export const CREATE_ORDER_COLUMN_CUSTOMER_PRODUCT_NUMBER = 'customerProductNumber';
export const CREATE_ORDER_COLUMN_REQUESTED_DELIVERY_DATE = 'requestedDeliveryDate';

import React from 'react';
import { IOrderDetailsResources } from '../order-details.props.autogenerated';
import { IOrderDetailResponse, ISoldTo } from '../../../actions/get-order-details.action';
import { formatAddress } from '../../../utilities/format-address';
import { NO_DATA_FALLBACK } from '../../../configuration';

function Address({ address, headline }: { address: ISoldTo; headline: string }) {
    return (
        <address>
            <h4 className='ms-order-details__address-information-title'>{headline}</h4>
            {formatAddress(address).map(line => (
                <p className='py-1' key={line}>
                    {line}
                </p>
            ))}
        </address>
    );
}

export default function addressInformationSection({
    orderDetails,
    resources
}: {
    orderDetails: IOrderDetailResponse;
    resources: IOrderDetailsResources;
}) {
    return (
        <div className='row'>
            <div className='col-12 col-sm-6 col-lg-12 col-xl-6'>
                {!!orderDetails.OrderDetail && Object.keys(orderDetails.OrderDetail.SoldTo).length ? (
                    <Address address={orderDetails.OrderDetail.SoldTo} headline={resources.orderDetailsSoldTo} />
                ) : (
                    NO_DATA_FALLBACK
                )}
            </div>
            <div className='col-12 col-sm-6 col-lg-12 col-xl-6 mt-3 mt-sm-0 mt-lg-3 mt-xl-0'>
                {!!orderDetails.OrderDetail && Object.keys(orderDetails.OrderDetail.ShipTo).length ? (
                    <Address address={orderDetails.OrderDetail.ShipTo} headline={resources.orderDetailsShipTo} />
                ) : (
                    NO_DATA_FALLBACK
                )}
            </div>
        </div>
    );
}

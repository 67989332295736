import { ICoreContext, IDateFormatOptions } from '@msdyn365-commerce/core';

/**
 * Formats date.
 * @param context - Core context.
 * @param date - Date to display.
 * @returns String value.
 */
// Example usage: formatDate(this.props.context, new Date(CreatedDate)

export const formatDate = (context: ICoreContext, date: Date | undefined) => {
    if (date === undefined) {
        return '';
    }

    const dateOptions: IDateFormatOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return context.cultureFormatter.formatDate(date, dateOptions);
};

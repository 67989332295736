import React from 'react';
import { ICoreContext } from '@msdyn365-commerce/core';
import OrderItems from './order-items-table';
import DocumentsTable from './documents-table';
import { IOrderDetailResponse } from '../../../actions/get-order-details.action';
import { IOrderDetailsResources } from '../order-details.props.autogenerated';
import GeneralInformationSection from './general-information-section';
import AddressInformationSection from './address-information-section';

interface OrderInformationProps {
    orderDetails: IOrderDetailResponse;
    resources: IOrderDetailsResources;
    context: ICoreContext;
    props: any;
}

const OrderInformation: React.FC<OrderInformationProps> = ({ orderDetails, context, resources, props }) => {
    return (
        <>
            <div className='row'>
                <div className='col-12 col-lg-8'>
                    <div className='msc-section-box'>
                        <h3 className='msc-section-box__title'>{resources.orderDetailsGeneralInformationSectionHeading}</h3>
                        <GeneralInformationSection context={context} orderDetails={orderDetails} resources={resources} />
                    </div>
                </div>
                <div className='col-12 col-lg-4 address-information'>
                    <div className='msc-section-box'>
                        <h3 className='msc-section-box__title'>{resources?.orderDetailsAddressInformationSectionHeading}</h3>
                        <AddressInformationSection orderDetails={orderDetails} resources={resources} />
                    </div>
                </div>
            </div>

            <OrderItems props={props} context={context} orderDetails={orderDetails} resources={resources}></OrderItems>

            <DocumentsTable context={context} orderDetails={orderDetails} resources={resources}></DocumentsTable>
        </>
    );
};

export default OrderInformation;
